<template>
  <base-section
    id="why-choose-us"
  >
    <a name="why-choose-us"></a>
    <base-section-heading
      color="grey lighten-2"
      :title="$t('chooseTitle')"
    >
      {{this.$t('chooseSubTitle')}}
    </base-section-heading>

    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in getCards"
            :key="card.title"
            cols="12"
            md="4"
          >
            <base-info-card v-bind="card" />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>

    <a name="discover-more"></a>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionWhyChooseUs',

    computed: {
      getCards () {
        return [
          {
            title: this.$t('chooseReason1Title'),
            subtitle: 'Best Productivity',
            text: this.$t('chooseReason1Content'),
            callout: '01',
          },
          {
            title: this.$t('chooseReason2Title'),
            subtitle: 'Ultra Fast Query Speed',
            text: this.$t('chooseReason2Content'),
            callout: '02',
          },
          {
            title: this.$t('chooseReason3Title'),
            subtitle: 'Easy To Use',
            text: this.$t('chooseReason3Content'),
            callout: '03',
          },
        ]
      },
    },
  }
</script>
